<!--
 * @Description: 维护回访报表
 * @Author: ChenXueLin
 * @Date: 2022-03-10 09:38:24
 * @LastEditTime: 2022-07-15 16:05:46
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpName">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpEnum"
                placeholder="客户名称"
                title="客户名称"
                @filterChange="corpHandleFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
                virtual
                clearable
                remote
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="companyId">
              <e6-vr-select
                v-model="searchForm.companyId"
                :data="companyEnum"
                placeholder="所属主体"
                title="所属主体"
                :props="{
                  id: 'companyId',
                  label: 'companyFullName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="attrIds">
              <e6-vr-select
                v-model="searchForm.attrIds"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpClass">
              <e6-vr-select
                v-model="searchForm.corpClass"
                :data="corpClassEnum"
                placeholder="客户分级"
                title="客户分级"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpLevel">
              <e6-vr-select
                v-model="searchForm.corpLevel"
                :data="corpLevelEnum"
                placeholder="客户级别"
                title="客户级别"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="revisitName">
              <el-input
                v-model="searchForm.revisitName"
                placeholder="回访人"
                title="回访人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="fixEnginnerId">
              <all-user-search
                v-model="searchForm.fixEnginnerId"
                clear
                placeholder="维护人"
                title="维护人"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'employeeName'
                }"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--2" prop="revisitTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="回访时间"
                ref="effectiveDatePicker"
                v-model="searchForm.revisitTime"
                title="回访时间"
                :picker-options="pickerOptions('searchForm.revisitTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="回访时间（始）"
                end-placeholder="回访时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="fixTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="维护时间"
                ref="effectiveDatePicker"
                v-model="searchForm.fixTime"
                title="维护时间"
                :picker-options="pickerOptions('searchForm.fixTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="维护时间（始）"
                end-placeholder="维护时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  getFixRevisit,
  findDownList,
  getCorpAttrDownList,
  getCompanydownList,
  getCorpMsg,
  exportFixRevisit
} from "@/api";
import allUserSearch from "@/components/allUserSearch";
import { exportData } from "@/utils/download";
export default {
  name: "maintainReport",
  data() {
    return {
      searchForm: {
        corpId: "", //客户id
        companyId: "", //所属主体
        attrIds: [], //客户属性
        corpAttr: "",
        corpClass: "", //客户分级
        corpLevel: "", //客户级别
        fixEnginnerId: "", //维护人
        revisitName: "", //回访人
        revisitTime: [], //回访时间
        revisitTimeStart: "", //回访时间开始
        revisitTimeEnd: "", //回访时间结束
        fixTime: [], //维护时间
        fixTimeStart: "", //维护时间开始
        fixTimeEnd: "", //维护时间结束
        pageIndex: 1,
        pageSize: 20
      },
      corpEnum: [], //客户名称
      corpAttrEnum: [], //客户属性列表
      corpClassEnum: [], //客户分级列表
      companyEnum: [], //所属主体列表
      corpLevelEnum: [], //客户级别
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "revisitId",
          display: true,
          fieldLabel: "回访ID",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companySimName",
          display: true,
          fieldLabel: "主体简称",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpLevelName",
          display: true,
          fieldLabel: "客户级别",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpClassName",
          display: true,
          fieldLabel: "客户分级",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "车牌号",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixName",
          display: true,
          fieldLabel: "维护人",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "fixDate",
          display: true,
          fieldLabel: "维护日期",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitTime",
          display: true,
          fieldLabel: "回访时间",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitMan",
          display: true,
          fieldLabel: "回访人",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitedManName",
          display: true,
          fieldLabel: "被回访人",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactPhone",
          display: true,
          fieldLabel: "联系电话",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "questionContext",
          display: true,
          fieldLabel: "问题描述",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "answerContext",
          display: true,
          fieldLabel: "答案内容",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [listPage, base, listPageReszie],
  computed: {},
  components: {
    allUserSearch
  },
  watch: {
    // 回访时间
    "searchForm.revisitTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.revisitTime = this.searchForm.revisitTime.reverse();
        }
        this.searchForm.revisitTimeStart = val[0] || "";
        this.searchForm.revisitTimeEnd = val[1] || "";
      }
    },
    // 维护时间
    "searchForm.fixTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.fixTime = this.searchForm.fixTime.reverse();
        }
        this.searchForm.fixTimeStart = val[0] || "";
        this.searchForm.fixTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["corpClass", "corpLevel"]),
          getCorpAttrDownList({}),
          getCompanydownList({ isManageEmp: false, companyId: "" })
        ];
        let [res, attrRes, companyEnumRes] = await Promise.all(promiseList);
        //客户分级
        this.corpClassEnum = this.getFreezeResponse(res, {
          path: "data.corpClass"
        });
        //客户级别
        this.corpLevelEnum = this.getFreezeResponse(res, {
          path: "data.corpLevel"
        });
        //客户属性
        this.corpAttrEnum = this.getFreezeResponse(attrRes, {
          path: "data"
        });
        //所属主体
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },

    // 客户列表 远程搜索
    corpHandleFilter: _.debounce(async function(val) {
      if (val) {
        this.corpDownList(val);
        this.searchForm.corpId = "";
      }
    }, 300),
    async corpDownList(val, enumKey = "corpEnum") {
      try {
        this.corpLoading = true;
        let corpEnumRes = await getCorpMsg({
          pageIndex: 1,
          pageSize: 200,
          corpName: val
        });
        this[enumKey] = this.getFreezeResponse(corpEnumRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.corpLoading = false;
      }
    },
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        let res = await getFixRevisit({
          ...this.searchForm,
          corpAttr: this.searchForm.attrIds.join(",")
        });
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportFixRevisit);
    }
  }
};
</script>
<style lang="scss" scoped></style>
